"use client"

import dynamic from "next/dynamic"
import type { ReactNode } from "react"

const RootStyleRegistry = dynamic(() => import("./emotion"), {
  ssr: false,
})
const ReactQuery = dynamic(() => import("./react-query"), {
  ssr: false,
})
const RootSnackbarProvider = dynamic(() => import("./snackbar"), {
  ssr: false,
})
const RootThemeProvider = dynamic(() => import("./theme"), {
  ssr: false,
})

export default function AppProvider({ children }: { children: ReactNode }) {
  return (
    <RootStyleRegistry>
      <ReactQuery>
        <RootThemeProvider>
          <RootSnackbarProvider>{children}</RootSnackbarProvider>
        </RootThemeProvider>
      </ReactQuery>
    </RootStyleRegistry>
  )
}
