"use client"

import { useEffect } from "react"

const _updateFaviconLinkById = (
  id: string,
  faviconSize: number,
  color: string,
) => {
  const favicon = document.getElementById(id) as HTMLLinkElement

  const canvas = document.createElement("canvas")
  canvas.width = faviconSize
  canvas.height = faviconSize

  const context = canvas.getContext("2d")
  const img = document.createElement("img")
  if (!favicon || !context) return
  img.src = favicon?.href

  img.onload = () => {
    // draw Original Favicon as Background
    context.drawImage(img, 0, 0, faviconSize, faviconSize)

    // set composite mode
    context.globalCompositeOperation = "source-in"

    // draw color
    context.fillStyle = color
    context.fillRect(0, 0, canvas.width, canvas.height)

    // replace favicon
    favicon.href = canvas.toDataURL("image/png")
  }
}

export const Favicon = ({
  src = [],
}: {
  src?: {
    id: string
    faviconSize: number
    color: string
  }[]
}) => {
  useEffect(() => {
    for (const { id, faviconSize, color } of src) {
      _updateFaviconLinkById(id, faviconSize, color)
    }
  }, [src])

  return <></>
}
